














































import { TranslateResult } from "vue-i18n";
import { Component, Vue, Watch } from "vue-property-decorator";

const RESEND_CODE_TIMEOUT = 30000;
@Component
export default class MessageForm extends Vue {
  sms = "";
  errorMsg: string | TranslateResult = "";
  loading = false;
  loadingNewCode = false;
  showResendCodeMsg = false;

  get allowResendSmsCode(): boolean {
    return this.$store.state.register.allowResendSmsCode;
  }

  async nextStep(): Promise<void> {
    this.loading = true;
    try {
      const res = await this.$store.dispatch("register/validateCode", this.sms);
      if (res?.codeValidated) {
        this.continue();
      } else {
        this.errorMsg = "SignUp_SMSCode_Verification_Error";
        this.$track({ label: "bsignup_inputcode_error_invalidcode" });
      }
    } catch ({ message }) {
      this.errorMsg = message as string;
    }
    this.loading = false;
  }

  continue(): void {
    this.$store.dispatch("register/setSMSCode", this.sms);
    this.$router.push({ name: "b-password-step" });
  }

  async sendCode(): Promise<void> {
    this.loadingNewCode = true;
    try {
      await this.$store.dispatch("register/sendCode");
    } catch (e) {
      console.error(e);
      this.errorMsg = "General_NetworkError_Description";
    }
    this.loadingNewCode = false;
  }

  resendCode(): void {
    if (this.allowResendSmsCode) {
      this.sendCode();
      this.showResendCodeMsg = true;
      setTimeout(() => (this.showResendCodeMsg = false), RESEND_CODE_TIMEOUT);
    } else {
      console.error("Wait 30s to send another SMS code");
    }
  }

  @Watch("sms")
  onSmsChange(): void {
    this.errorMsg = "";
  }

  mounted(): void {
    this.showResendCodeMsg = false;
    if (this.$store.state.register.phoneIsValid) {
      this.sendCode();
    } else {
      this.$router.push({ name: "phone-step" });
    }
  }
}
