var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('h1',{staticClass:"text-4xl font-medium"},[_vm._v("Añade el código")]),_c('p',{staticClass:"mt-6 text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("SignUp_SMSCode_Caption"))+" ")]),_c('BaseInputText',{staticClass:"mt-6",attrs:{"autofocus":"","rules":"required","label":_vm.$t('General_SecurityCode'),"errorMsg":_vm.$t(_vm.errorMsg),"pattern":"\\d*"},model:{value:(_vm.sms),callback:function ($$v) {_vm.sms=$$v},expression:"sms"}}),(!_vm.showResendCodeMsg)?_c('button',{directives:[{name:"track",rawName:"v-track:click",value:({
      label: 'bsignup_inputcode_click_resendcode',
    }),expression:"{\n      label: 'bsignup_inputcode_click_resendcode',\n    }",arg:"click"}],staticClass:"text-blue-600 py-2 my-4 flex items-center text-left",attrs:{"type":"button","disabled":!_vm.allowResendSmsCode},on:{"click":_vm.resendCode}},[_vm._v(" "+_vm._s(_vm.$t("SignUp_SMSCode_Retry_Button"))+" "),_c('BaseIcon',{staticClass:"ml-2",attrs:{"solid":"","icon":"arrow-right"}})],1):_vm._e(),_c('BaseButton',{directives:[{name:"track",rawName:"v-track:view",value:({
      label: 'bsignup_inputcode_view',
    }),expression:"{\n      label: 'bsignup_inputcode_view',\n    }",arg:"view"},{name:"track",rawName:"v-track:click",value:({
      label: 'bsignup_inputcode_click_next',
    }),expression:"{\n      label: 'bsignup_inputcode_click_next',\n    }",arg:"click"}],staticClass:"block mt-8",attrs:{"type":"submit","loading":_vm.loading,"disabled":!valid,"primary":""},on:{"click":_vm.nextStep}},[_vm._v(_vm._s(_vm.$t("General_Continue")))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }